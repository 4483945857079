import { window } from '@wix/photography-client-lib';

export default class ItemActionsHelper {
  constructor(galleryWrapper, props, isStoreGallery) {
    this.galleryWrapper = galleryWrapper;
    this.galleryWrapperProps = props;
    this.isStoreGallery = isStoreGallery;
    this.update = this.update.bind(this);
    this.initItemActions = this.initItemActions.bind(this);
    this.useItemActions = this.useItemActions.bind(this);
    this.onLoveCountsFetched = this.onLoveCountsFetched.bind(this);
    this.updateLocalLoveData = this.updateLocalLoveData.bind(this);
    this.onItemActionTriggered = this.onItemActionTriggered.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.onCurrentItemChanged = this.onCurrentItemChanged.bind(this);
    this.onLinkNavigation = this.onLinkNavigation.bind(this);
    this.handleItemActions = this.handleItemActions.bind(this);

    this.itemActions = null;
  }

  update(props) {
    this.galleryWrapperProps = props;
  }

  initItemActions() {
    this.useItemActions('initWidgetData', {
      compId: this.galleryWrapperProps.id,
      pageId: this.galleryWrapperProps.pageId,
      styleId: this.galleryWrapperProps.styleId,
      galleryId: this.galleryWrapperProps.galleryId,
      isStoreGallery: this.isStoreGallery,
      baseUrl: this.galleryWrapperProps.baseUrl,
      pageUrl: this.galleryWrapperProps.pageUrl,
      fullscreenUrl: this.galleryWrapperProps.fullscreenUrl,
      instanceId: this.galleryWrapperProps.instanceId,
      onLoveCountsFetched: this.onLoveCountsFetched,
      viewMode: this.galleryWrapper.siteHelper.parseViewMode(
        this.galleryWrapperProps.viewMode,
      ),
    });
  }

  getItemActions() {
    if (!!this.itemActions && typeof this.itemActions === 'object') {
      return Promise.resolve(this.itemActions);
    } else {
      return new Promise((resolve) => {
        // todo: fix
        import(
          /* webpackChunkName: "ItemActions" */ '@wix/photography-client-lib/dist/es/src/item/itemActions'
        ).then((module) => {
          const ItemActions = module.default;
          this.itemActions = new ItemActions();
          resolve(this.itemActions);
        });
      });
    }
  }

  readFromItemActions(itemActions, method, args) {
    if (!!itemActions && typeof itemActions === 'object') {
      if (typeof itemActions[method] === 'function') {
        return itemActions[method](...args);
      } else {
        return itemActions[method];
      }
    }
    return null;
  }

  useItemActions(method, ...args) {
    const retVal = this.readFromItemActions(this.itemActions, method, args);
    if (retVal !== null) {
      return retVal;
    } else {
      this.getItemActions().then((itemActions) => {
        return this.readFromItemActions(itemActions, method, args);
      });
    }
  }

  onLoveCountsFetched(photoId2Love) {
    const itemsLoveData = {};
    Object.entries(photoId2Love).forEach(([id, loveCount]) => {
      itemsLoveData[id] = {
        loveCount,
        isLoved: this.useItemActions('isLoved', id),
      };
    });
    this.galleryWrapper.setState({ itemsLoveData });
  }

  updateLocalLoveData(id) {
    const itemsLoveData = this.galleryWrapper.state.itemsLoveData;
    let updatedItemLoveData;
    if (itemsLoveData[id]) {
      updatedItemLoveData = {
        isLoved: !itemsLoveData[id].isLoved,
        loveCount: itemsLoveData[id].isLoved
          ? itemsLoveData[id].loveCount - 1
          : itemsLoveData[id].loveCount + 1,
      };
    } else {
      updatedItemLoveData = {
        isLoved: true,
        loveCount: 1,
      };
    }
    const updatedItemsLoveData = Object.assign({}, itemsLoveData, {
      [id]: updatedItemLoveData,
    });
    this.galleryWrapper.setState({ itemsLoveData: updatedItemsLoveData });
  }

  onItemActionTriggered(itemProps, styleParams) {
    const itemClick = styleParams.itemClick;
    const itemClickProps = {
      dto: this.galleryWrapper.itemsHelper
        .pgItemsProps()
        .items.find((item) => item.itemId === itemProps.id),
      id: itemProps.id,
      idx: itemProps.idx,
    };
    // this.onItemClicked(itemClickProps, itemClick);
    if (itemClick === 'fullscreen') {
      this.galleryWrapper.fullscreenHelper.toggleBrowserFullscreen();
      this.galleryWrapper.fullscreenHelper.animatedOpenFullscreen(
        itemClickProps,
      );
    } else if (itemClick === 'expand') {
      this.galleryWrapper.fullscreenHelper.animatedOpenFullscreen(
        itemClickProps,
      );
    } else if (itemClick === 'link') {
      if (typeof this.galleryWrapperProps.onLinkNavigation === 'function') {
        this.galleryWrapperProps.onLinkNavigation(itemClickProps, itemClick);
      }
    }
  }

  onItemClicked(itemProps) {
    // }, event) {
    const itemClickProps = {
      dto: this.galleryWrapper.itemsHelper
        .pgItemsProps()
        .items.find((item) => item.itemId === itemProps.id),
      id: itemProps.id,
      idx: itemProps.idx,
    };
    if (typeof this.galleryWrapperProps.onItemClicked === 'function') {
      this.galleryWrapperProps.onItemClicked(itemClickProps);
    }
  }

  onCurrentItemChanged(item) {
    if (typeof this.galleryWrapperProps.onCurrentItemChanged === 'function') {
      this.galleryWrapperProps.onCurrentItemChanged(item);
    }
  }

  onLinkNavigation(item) {
    const { linkData } = item;
    if (!linkData || !linkData.type) {
      console.error(
        'link navigation failed due to invalid link data',
        linkData,
      );
      return;
    }

    switch (linkData.type) {
      case 'ExternalLink':
        window.open(linkData.url, linkData.target);
        break;
      default:
        const itemForNavigation = { dto: item.dto };
        delete itemForNavigation.resizeMediaUrl;
        this.galleryWrapperProps.onLinkNavigation(itemForNavigation);
    }
  }

  handleItemActions(action, actionData) {
    let item;
    switch (action) {
      case 'share':
        this.useItemActions('share', actionData.network, actionData.shareProps);
        break;
      case 'downloadTextItem':
        item = {
          html: actionData.html,
          style: actionData.style,
        };
        this.useItemActions('downloadTextItem', item);
        break;
      case 'postLoveActivity':
        item = {
          type: actionData.type,
          itemId: actionData.itemId,
          id: actionData.id,
          idx: actionData.idx,
          hashtag: actionData.hashtag,
        };
        this.useItemActions('postLoveActivity', item);
        break;
      case 'toggleLove':
        this.useItemActions('toggleLove', actionData.id);
        break;
      default:
        break;
    }
  }
}
